.sidebar {
  height: 100vh;
  width: 300px;
  position: fixed;
  border-right: 1px solid rgb(198, 198, 198);
  z-index: 100;
}

.pointer {
  cursor: pointer;
}

.table-filler {
  height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-toggle {
  cursor: pointer;
}

.profile-dropdown {
  position: absolute;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform: translate3d(0px, -34px, 0px);
}

.content {
  width: auto;
  min-height: 100vh;
  margin-left: 300px;
  padding: 1.5rem 1.5rem 0 1.5rem;
}

.login {
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 430px;
  padding: 15px;
  margin: auto;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.fit-content {
  width: 135px;
}

.smallInput {
  width: 70px !important;
  flex: none !important;
  display: inline;
  margin: 0 5px;
}

.group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.nutritionTop {
  display: flex;
  justify-content: space-between;
}

.fakeTextArea > p {
  margin: 0;
}

.fakeTextArea div {
  min-height: 200px;
  height: calc(100% - 1.5rem);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
}
